@import "~@fider/assets/styles/variables.scss";

.c-dropdown {
  position: relative;

  &.is-inline {
    display: inline-block;

    .c-dropdown-menu {
      width        : auto;
      border-radius: 5px;
      margin-top   : 5px;
    }
  }

  &.is-open {
    .c-dropdown-arrow {
      border-color: transparent transparent $gray-400;
      border-width: 0 5px 5px;
    }
  }

  &.m-left {
    .c-dropdown-menu {
      right: 0;
    }
  }

  &.m-style-normal {
    .c-dropdown-control {
      border: 1px solid $gray-300;

      &:hover {
        box-shadow: 0 1px 0 rgba(0, 0, 0, 0.06);
      }
    }
  }

  .c-dropdown-control {
    position        : relative;
    cursor          : pointer;
    overflow        : hidden;
    background-color: transparent;
    border-radius   : 3px;
    box-sizing      : border-box;
    color           : $gray-900;
    outline         : none;
    padding         : 8px 52px 8px 10px;
    transition      : all 200ms ease;

    &::first-letter {
      text-transform: capitalize;
    }

    input {
      border: none;
      width : 100%;
    }

    .c-dropdown-placeholder {
      color: $gray-300;
    }

    .c-dropdown-arrow {
      border-color: $gray-500 transparent transparent;
      border-style: solid;
      border-width: 5px 5px 0;
      content     : " ";
      display     : block;
      height      : 0;
      margin-top  : -ceil(2.5);
      position    : absolute;
      right       : 10px;
      top         : 40%;
      width       : 0;
    }
  }

  .c-dropdown-menu {
    background-color          : $white;
    border                    : 1px solid $gray-300;
    box-shadow                : 0 1px 0 rgba(0, 0, 0, 0.06);
    box-sizing                : border-box;
    margin-top                : -1px;
    overflow-y                : auto;
    position                  : absolute;
    width                     : 100%;
    top                       : 100%;
    z-index                   : 1000;
    min-width                 : -webkit-min-content;
    min-width                 : -moz-min-content;
    min-width                 : min-content;
    max-height                : 300px;
    -webkit-overflow-scrolling: touch;

    >* {
      white-space: nowrap;
    }

    .c-dropdown-menu-header {
      padding       : 12px 10px;
      text-transform: uppercase;
      font-weight   : 700;
      font-size     : 0.78571429em;
    }



    .c-dropdown-item {
      box-sizing : border-box;
      cursor     : pointer;
      display    : block;
      padding    : 12px 10px;
      line-height: $font-size;
      color      : $gray-500;


      &:last-child {
        border-bottom-right-radius: 3px;
        border-bottom-left-radius : 3px;
      }

      &:hover {
        background-color: $gray-100;
      }

      &.is-selected {
        color      : $gray-900;
        font-weight: 700;
      }

      .c-tag {
        margin-right: 6px;
      }

      svg {
        display     : none;
        margin-right: 6px;


        path {
          fill: $main-color;
        }
      }

      .selected-tag {
        color      : $gray-900;
        font-weight: 700;
      }


    }

    .c-dropdown-noresults {
      box-sizing: border-box;
      color     : $gray-400;
      cursor    : default;
      display   : block;
      padding   : 8px 10px;
    }
  }
}