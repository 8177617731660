@import "~@fider/assets/styles/variables.scss";

.c-heading {
  margin: 0px 0px 10px 0px;

  .c-heading-content {
    display: inline-block;
    vertical-align: middle;

    .c-heading-subtitle {
      font-weight: 400;
      margin-top: 5px;
      color: $gray-700;
      font-size: $font-size;
    }
  }

  .c-heading-icon + .c-heading-content {
    padding-left: 10px;
  }

  .c-heading-icon {
    font-size: 30px;
    @include media("sm") {
      font-size: 20px;
    }
    display: inline-block;
    vertical-align: middle;
    &.circular {
      border-radius: 500em !important;
      padding: 0.5em 0 !important;
      -webkit-box-shadow: 0 0 0 0.1em rgba(0, 0, 0, 0.1) inset;
      box-shadow: 0 0 0 0.1em rgba(0, 0, 0, 0.1) inset;
      width: 2em !important;
      height: 2em !important;
      text-align: center;
      .icon {
        vertical-align: top;
      }
    }
  }

  &.m-dividing {
    box-shadow: 0px 1px 0px 0px rgba(34, 36, 38, 0.1);
    padding-bottom: 5px;
    margin-bottom: 10px;
  }

  &.m-small {
    .c-heading-subtitle {
      font-size: $font-size-small;
    }
  }
}
