@import "~@fider/assets/styles/variables.scss";

.c-toggle {
  cursor: pointer;

  &.m-disabled {
    cursor: not-allowed;
    label {
      cursor: not-allowed;
    }
  }

  input {
    display: none;
  }

  .text {
    margin-left: 5px;
    vertical-align: middle;
  }

  label {
    display: inline-block;
    vertical-align: middle;
    width: 30px;
    height: 15px;
    border-radius: 10px;
    position: relative;
    cursor: pointer;
    background: $gray-300;
  }

  .switch {
    position: absolute;
    width: 13px;
    height: 13px;
    margin-top: 1px;
    margin-left: 1px;
    border-radius: 1em;
    box-shadow: 0px 1px 5px 0px $gray-500;
    background: $gray-100;
    transition: transform 0.2s ease-in;
  }

  input:checked ~ label {
    background: $green-500;
  }

  input:checked ~ label .switch {
    background: $gray-100;
    transform: translatex(15px);
    transition: transform 0.2s ease-in;
  }
}
