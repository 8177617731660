@import "~@fider/assets/styles/variables.scss";

.c-message {
  padding: 15px;
  margin-bottom: 10px;
  display: flex;
  align-items: center;

  .icon {
    margin-right: 6px;
    height: 14px;
  }

  &.m-success {
    color: $green-700;
    border-left: 2px solid $green-500;
    background-color: $green-50;
  }

  &.m-warning {
    color: $yellow-700;
    border-left: 2px solid $yellow-500;
    background-color: $yellow-50;
  }

  &.m-error {
    color: $red-700;
    border-left: 2px solid $red-500;
    background-color: $red-50;
  }
}
