@import "~@fider/assets/styles/variables.scss";

.c-vote-counter {
  order: 1;

  button,
  button.m-voted,
  button.m-disabled {
    font-size       : 18px;
    border          : none;
    width           : 58px;
    font-weight     : 700;
    cursor          : pointer;
    padding         : 8px;
    border-radius   : 4px;
    background-color: $white;
    text-align      : center;
    color           : $gray-700;
    border          : 1px solid $gray-300;
    display         : flex;
    align-items     : center;
  }

  button.c-vote-counter__button--voted {
    color: $red-500;
  }

  button.m-voted {
    color : $main-color;
    border: 1px solid $main-color;
  }

  button.m-disabled {
    @include disabled();
    cursor: not-allowed;
  }

  button svg {
    margin-right: 6px;
  }

  @include media(">=md") {
    margin-right: 24px;
    order       : unset;
  }
}