@import "~@fider/assets/styles/variables.scss";

.c-segment {
  position: relative;
  padding: 15px 0;
  margin: 10px 0;
  background-color: $white;
  border-radius: 5px;
  transition: all 0.2s ease;

  &:first-child {
    margin-top: 0em;
  }
}

.c-segments {
  position: relative;
  box-shadow: 0px 2px 5px 0px $gray-300;
  border-radius: 5px;
  margin: 10px 0;
  transition: all 0.2s ease;

  &:hover {
    box-shadow: 0px 5px 10px 0px $gray-300;
  }

  &:first-child {
    margin-top: 0em;
  }

  .c-segment {
    margin: 0;
    box-shadow: none;
    border: none;
    border-bottom: 0.1px solid rgba(34, 36, 38, 0.15);
    &:last-child {
      border-bottom: none;
    }
  }
}
