@import "~@fider/assets/styles/variables.scss";

@mixin menu-item {
  position       : relative;
  vertical-align : middle;
  line-height    : 1;
  text-decoration: none;
  flex           : 0 0 auto;
  user-select    : none;
  padding        : 15px 10px;
  display        : flex;
  align-items    : center;
}

#c-header {
  background: #fff;

  .c-env-info {
    padding         : 2px;
    border-radius   : 0px;
    font-size       : 10px;
    color           : $red-900;
    border-bottom   : 1px solid $red-900;
    background-color: rgba($red-400, 0.05);
  }

  .c-menu {
    margin-top      : 0px;
    height          : 50px;
    border-radius   : 0px;
    border          : none;
    display         : flex;
    box-shadow      : 0 1px 2px 0 rgba(34, 36, 38, 0.15);
    // margin-bottom: 10px;

    .container {
      display: flex;
    }

    .backToPortal {
      padding   : 15px;

      svg {
        font-size: 1.25em;
      }

      svg path {
        color: $gray-900;
      }
    }

    @include media("sm") {
      height: 40px;

      .container {
        width       : 100% !important;
        margin-left : 0 !important;
        margin-right: 0 !important;
      }

      .backToPortal {
        padding: 10px;
      }
    }

    .c-menu-item-title {
      @include menu-item;
      color: #484848;

      img {
        margin-right: 20px;
        width: 100px;

        @include media("sm") {
          margin-right: 10px;
        }
      }

      h1 {
        font-size  : $font-size-big;
        margin     : 0 0 0 0;
        font-weight: 600;

        @include media("sm") {
          font-size: $font-size;
          max-width: 240px;
        }
      }
      
      @include media("xs"){
        gap: 50px;
      }
    }

    .c-menu-item-signin {
      @include menu-item;
      cursor       : pointer;
      font-size    : 90%;
      margin-left  : auto;
      margin-bottom: 0;

      >span {
        font-weight   : 600;
        text-transform: uppercase;
        color         : $gray-700;
      }

      &:hover {
        >span {
          color: $gray-900;
        }
      }

      .c-menu-user {
        display         : none;
        position        : absolute;
        overflow        : visible;
        width           : auto;
        height          : auto;
        top             : 100% !important;
        opacity         : 1;
        z-index         : 11;
        background-color: $white;
        box-shadow      : $basic-shadow;
        min-width       : 160px;

        span {
          vertical-align: middle;
        }

        .c-menu-user-heading {
          white-space   : nowrap;
          font-size     : $font-size-mini;
          padding       : 10px;
          display       : block;
          color         : $gray-700;
          text-transform: uppercase;
          font-weight   : 600;
          cursor        : default;
        }

        .c-menu-user-item {
          font-size  : $font-size-small;
          padding    : 8px 16px 8px 8px;
          line-height: 24px;
          display    : block;
          color      : $gray-700;

          &:hover {
            background-color: $gray-200;
          }

          svg {
            margin-right: 6px;
          }

        }

        .c-menu-user-divider {
          border-top: 1px solid rgba(34, 36, 38, 0.1);
          display   : block;
          cursor    : default;
        }
      }

      &:hover {
        .c-menu-user {
          display: block;
          left   : auto;
          right  : 0;
        }
      }

      .c-unread-dot {
        width           : 10px;
        height          : 10px;
        border-radius   : 5px;
        margin-left     : -10px;
        margin-top      : -25px;
        background-color: $red-500;
        border          : 1px solid $white !important;
      }

      .c-unread-count {
        display         : inline-block;
        background-color: $red-500;
        position        : absolute;
        right           : 5px;
        padding         : 2px 8px;
        color           : $white;
        border-radius   : 100px;
        font-size       : $font-size-mini;
        text-align      : center;
      }
    }
  }
}