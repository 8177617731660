@import "~@fider/assets/styles/variables.scss";

.c-hint {
  position: relative;
  padding: 15px 35px 15px 15px;
  margin-bottom: 10px;
  color: $blue-700;
  border-left: 2px solid $blue-500;
  background-color: $blue-50;

  .close {
    position: absolute;
    right: 15px;
    top: 15px;
    font-size: $font-size-large;
    cursor: pointer;
  }
}
